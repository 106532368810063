.ant-menu-dark, .ant-menu-dark .ant-menu-sub {
  background: var(--tus-black);
  color: var(--tus-white);
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub,
.ant-menu-submenu > .ant-menu,
.ant-menu-item:active, .ant-menu-submenu-title:active {
  background: var(--tus-black);
}

.ant-menu-item-selected {
  background-color: transparent;
}

.ant-menu-item > a,
.ant-menu-item > a:hover,
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a {
  color: var(--tus-white);
}

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent;
  text-decoration: underline;
}

.ant-descriptions-item-label {
  font-weight: bold;
}

.ant-page-header {
  padding: 16px 0;
}

.ant-page-header-heading-title {
  font-family: barcaFont;
  font-weight: normal;
  font-size: 24px;
}

.ant-page-header-heading-sub-title {
  font-weight: 700;
}

.ant-menu {
  line-height: inherit;
  background-color: transparent;
  color: var(--tus-white);
  font-weight: 500;
  font-size: 1.17em
}

.ant-card-head-title {
  font-weight: 700;
  text-overflow: unset;
  white-space: unset;
}

.ant-card-meta-title {
  white-space: unset;
}

.sponsorsCard .ant-card-body {
  display: none;
}

.sponsorsCard.ant-card-bordered {
  border-color: var(--tus-black);
  min-height: 132px;
  display: grid;
  place-items: center;
}

.sponsorsCard.ant-card-bordered .ant-card-cover img {
  max-height: 90px;
}

.ant-card.ant-card-bordered {
  overflow: hidden;
}
