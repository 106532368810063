.box{
  position: relative;
  width: 100%;
  height: 13%;
  background: var(--tus-black);
  box-sizing: border-box;
}

.box:after{
  position: absolute;
  width: 100%;
  height: 50%;
  content: '';
  background: var(--tus-red);
  right: 0;
  left: 0; 
  transform: skew(0, -1deg);
}

.box.top:after  {
  top: 0;
  transform-origin: top left;
}

.box.bottom:after  {
  bottom: 0;
  transform-origin: bottom right;
}