:root {
  --tus-red: rgb(209, 18, 22);
  --tus-black: rgb(35,31,32);
  --tus-white: white;
}

body {
  margin: 0;
  font-family: barcaFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: url(imgs/background1_min.jpg);
  background-position: center top;
  background-size: auto;
  background-repeat: no-repeat;
  background-attachment: scroll;
  
  background-Color: #222222; 
  min-Height:100vh;
  padding: 0 1% 0%  1%;
  overflow-y: overlay;
  overflow-x: hidden;
  max-width: 100%;
}
h3 {
  font-weight: 700;
}
.nav-icon-class {
  vertical-align: text-bottom;
}

/* Vertical Timeline CSS */
.vertical-timeline-element-date {
  margin-left: 3%;
  margin-right: 3%;
}

/* fixing same name css files */ 
li:nth-child(3) {
  margin-top: unset;
}
li:nth-child(3).rt-event {
  margin-top: 400px;
}
@media (max-width: 600px){
  li:nth-child(3).rt-event {
    margin-top: 1em;
  } 
}


/* Fonts*/
@font-face {
  font-family: barcaFont;
  src: url('./fonts/font_barca_by_roca-webfont.eot');
  src: url('./fonts/font_barca_by_roca-webfont.eot?#iefix') format('embedded-opentype'),
        url('./fonts/font_barca_by_roca-webfont.woff2') format('woff2'),
        url('./fonts/font_barca_by_roca-webfont.woff') format('woff'),
        url('./fonts/font_barca_by_roca-webfont.ttf') format('truetype'),
        url('./fonts/font_barca_by_roca-webfont.svg#barcelona_2012_by_rocaregular') format('svg');
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}