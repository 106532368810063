.rt-label, .rt-header-container, .rt-title {
  background: var(--tus-red);
  color: var(--tus-white);
} 
.rt-event .rt-svg-container .rt-arrow, .rt-event .rt-dot {
  color: var(--tus-red);
}

@media (pointer: coarse)  {
  .rt-event {
    min-height: fit-content;
  }
}