.header {
  background-color: var(--tus-black);
  min-height: 100px;
  padding-top: 5px;
  margin-bottom: 1em;
}
.header img {
  z-index: 1;
  position: absolute;
  height: 100px;
}
.header h1, .header h2 {
  margin-top: 0%;
  color: var(--tus-white);
  transform: rotate(-1deg);
}
#menuToggle {
  color: var(--tus-white);
  border: none;
}
.top{
  text-align: center;
}
.banderole {
  background-color: var(--tus-red);
  height: 30%;
  transform: skew(0, -1deg);
  transform-origin: bottom right;
}